import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");
  if (sanitizedPhoneNumber.length >= 5) {
    return `+${sanitizedPhoneNumber.substring(0, 3)}${"*".repeat(
      sanitizedPhoneNumber.length - 5
    )}${sanitizedPhoneNumber.slice(-2)}`;
  } else {
    return `+${sanitizedPhoneNumber}`;
  }
};
