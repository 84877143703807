import getAxiosInterceptor from "../../utils/axiosInterceptor";

export const loginApi = async (data: any) => {
  const axiosInterceptor = getAxiosInterceptor();
  return axiosInterceptor.post("/eposta/users/login/", data);
};

export const verifyCodeApi = async (data: any) => {
  const axiosInterceptor = getAxiosInterceptor();
  return axiosInterceptor.post("/eposta/users/verify_login_code/", data);
};

export const resentVerifyCodeApi = async (data: any) => {
  const axiosInterceptor = getAxiosInterceptor();
  return axiosInterceptor.post("/eposta/users/resent_login_code/", data);
};

export const addigitaladdress = async (data: any) => {
  const axiosInterceptor = getAxiosInterceptor();
  return axiosInterceptor.post("/eposta/users/resent_login_code/", data);
};
